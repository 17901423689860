export function useGTag() {
  const jobsSearchStore = useJobsSearchStore();
  const userStore = useUserStore();

  window.dataLayer = window.dataLayer || [];

  function command(...args: unknown[]) {
    window.dataLayer.push(arguments);
  }

  function event(name: string, data?: Record<PropertyKey, unknown>) {
    window.dataLayer.push({ event: name, ...data });
  }

  return {
    reset() {
      window.dataLayer.push(function () {
        this.reset();
      });
    },
    js(date: Date) {
      command('js', date);
    },
    config(id: string) {
      command('config', id);
    },
    logUser(
      eventName:
        | 'auth:session-start'
        | 'auth:login'
        | 'auth:register'
        | 'auth:google-login'
        | 'auth:google-one-tap'
        | 'auth:logout',
    ) {
      if (userStore.isAuthenticated && userStore.user) {
        event(eventName, { user_id: userStore.user.id });
      }
    },
    logJob(
      eventName:
        | 'job-details'
        | 'job-apply'
        | 'job-apply:external'
        | 'job-apply:offline'
        | 'job-apply:direct_link',
      pageType?: 'offerdetail' | 'conversionintent' | 'conversion',
    ) {
      const job = jobRepository.use();
      if (!job.value) {
        logger.warn('Tried to logJob via GTM, but no job present');
        return;
      }
      const jobData = {
        job_id: job.value.id,
        job_title: job.value.position?.name,
        job_category: job.value.rootPosition?.name,
        job_locid: job.value.location.summary,
        job_application_link: job.value.applicationTypes.map((t) => t.type).join(', '),
      };
      if (pageType) {
        event(eventName, { ...jobData, job_pagetype: pageType });
      } else {
        event(eventName, jobData);
      }
    },
    logSearch() {
      const { filters } = jobsSearchStore;
      event('search-results', { ...filters.static, ...filters.dynamic });
    },
    logNotification(key: string, value: boolean) {
      event('user:notifications', { [key]: value });
    },
    logRecommended(page: 'searchlist' | 'conversion' | 'jobs') {
      event('recommender:seen', { page });
    },
  };
}
